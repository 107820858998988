@import "../GlobalCssVars";

.component-textcard-container {
    overflow:hidden;
    padding: 2rem;
    text-align: center;
    margin: 2px;
    background-color: white;
    box-shadow: rgba(0,0,0,0.3) 0.1rem 0.2rem 0.4rem;
    color:rgba(0,0,0,0.88);
    a {
        color: black;
    }

    &:hover {
        background-color: $mainColor;
        color: white;

        a {
            color: white;
        }
    }

    .title {
        font-weight: 800;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .content {
        font-weight: 400;
        margin-bottom: 1rem;
    }

}