.page-index-container {
    .carousel-container {
        width: 100%;
        height: calc(51vw + 1.01rem);
        max-height: 90vh;
        overflow-y: hidden;

    }

    .carousel-item-container {
        width: 100%;
        height: 100%;
    }

    .carousel-item-img {
        display: inline-block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        /*图片居中*/
        object-fit: cover;
    }

    .carousel-item-img-1 {}

    .carousel-item-img-2 {
        object-position: left center;
    }

    .carousel-info-container {
        position: absolute;
        top: 10vh;
        left: 8vw;
        width: 15rem;
        height: 15rem;
        padding: 1.81rem 0.42rem 0px 1.39rem;
        background-color: white;
        border-radius: 0 0 100px;
        z-index: 1;
    }

    .carousel-info-title {
        font-family: Gilroy;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1rem;
        color: rgb(130, 130, 130);
    }

    .carousel-info-content {
        font-family: Gilroy-Bold;
        font-weight: 500;
        font-size: 2.9rem;
        line-height: 1.5;
        margin-top: 1.1rem;
        text-align: left;
    }

}