@import "../GlobalCssVars";

.component-header-container {
    background-color: $mainColor;
    height: 10vh;
    width: 100vm;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-logo {
        display: flex;
        justify-content: center;
        width: 300px;
        margin-left: 4rem;
        overflow: hidden;
        cursor: pointer;
    }

    .header-logo .header-logo-text {
        width: 60%;
        font-family: "FangSong_GB2312";
        color: white;
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        margin: 10px;
        align-items: center;
        justify-content: flex-start;
    }

    .header-logo .header-logo-img {
        width: 20%;
        object-fit: cover;
        //filter: drop-shadow(#fff 100px 0);
        //transform: translateX(-115px);
    }

    @media only screen and (max-width: 950px) {
        height: 70px;

        .header-logo {
            margin-left: 2rem;
        }
        .header-logo .header-logo-text {
            font-size: 1rem;
        }
    }

    .header-menu {
        visibility: hidden;
        display: inherit;
        margin-right: 10rem;
    }

    .header-menu .header-menu-item {
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: 1000;
        font-size: 1.2rem;
        color: white;
        margin: 1em;
        cursor: pointer;
    }

    .menu-item-dropdown {
        background-color: red;
        width: 300px;
    }
    
    .small-menu-icon {
        color:white;
        font-size: 2rem;
        margin-right: 1.5rem;
    }
}