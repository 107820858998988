.page-blog-container {
    width: 100%;
    .top-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin-bottom: 50px;
    }

    .title {
        margin-bottom: 50px;
        .sub-title {
            font-style: italic;
            color: darkgray;
        }
    }

    .content {
        width: 80%;
        margin-left: 10%;
    }
}