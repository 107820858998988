@import "../GlobalCssVars";
.markdown-h1-container {
    h1 {
    }
}
.markdown-h2-container {
    h2 {
        border-left: 4px solid $mainColor;
        padding-left:10px;
    }
}

.markdown-h3-container {
    h3 {
        color:$mainColor;
    }
}

.markdown-img-container {
    display: flex;
    justify-content: center;
    img {
        max-width: 80%;
        object-fit:contain;
    }
}