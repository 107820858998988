.component-cardhorizontal-container {
    height:100%;
    width:100%;
    border-radius: 0.9rem;
    overflow:hidden;
    display:flex;
    box-shadow:rgba(0, 0, 0, 0.3) 0.1rem 0.1rem 0.2rem;
    background-color:white;
    &:hover {
        box-shadow:rgba(0, 0, 0, 0.5) 0.2rem 0.2rem 0.5rem;
    }
    .image {
        width: 50%;
        overflow:hidden;
    }
    .content-container {
        width: 50%;
        padding: 1rem;
        .title {
            font-weight: 500;
            font-size:2rem;

        }
        .content {
            font-weight: 400;

        }
    }
}