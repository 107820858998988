@import "./components/header";
@import "./components/footer";
@import "./components/textCard";
@import "./components/textWithQuotation";
@import "./components/dividerWithText";
@import "./components/cardHorizontal";
@import "./components/cardVertical";
@import "./components/markdownBlog";

@import "./pages/pageRoot";
@import "./pages/blogPage";
@import "./pages/index";

.App {
    padding: 0;
    margin: 0;
}