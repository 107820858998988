@import "../GlobalCssVars";

.component-footer-container {
  background-color: $mainColor;
  height: 10vh;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    height: 15vh;
  }

  .footer-logo {
    width: 15rem;
    margin-left: 4rem;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
      margin-left: 1rem;
    }
  }

  .footer-logo .footer-logo-text {
    font-family: "FangSong_GB2312";
    color: white;
    font-size: 2rem;
    font-weight: 500;
    font-style: italic;
    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  .copy-right-container {
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content:center;
    text-align: right;
    color:white;
    padding-right: 5%;

    .beian {
      display: flex;
      justify-content: end;
      align-items: center;
      margin: 0;
      padding-top: 1rem;
      a {
          padding:5px;
          color: white;
      }
    }
  }
}