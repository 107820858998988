.component-textwithquotation-container {
    $quotationMarkSize:3rem;
    margin: 3rem;
    width: fit-content;
    .quotation {
        margin: 0;
        font-weight:1000;
        font-size: $quotationMarkSize;
        font-family:SimHei;
        font-style: italic;
    }
    .quotation-left {
        text-align:left;
        margin-bottom: -$quotationMarkSize / 2;
    }
    .quotation-right {
        text-align:right;
    }

    .content {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: $quotationMarkSize + 1;
        margin-right: $quotationMarkSize + 1;
        font-weight: 400;
        font-style: italic;
        font-size: 2rem;
        text-align: center;
    }
}