.component-dividerwithtext-container {

    .divider-with-text-container {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 16px 0;
    }

    .divider-with-text-container::before {
        display: block;
        border-block-start: 2px solid darkgray;
        content: "";
    }

    .divider-with-text-container::after {
        display: block;
        border-block-start: 2px solid darkgray;
        content: "";
    }

    .divider-with-text-container-left::before {
        width: 5%;
    }

    .divider-with-text-container-left::after {
        width: 95%;
    }

    .divider-with-text-container-middle::before {
        width: 50%;
    }

    .divider-with-text-container-middle::after {
        width: 50%;
    }

    .divider-with-text-container-right::before {
        width: 95%;
    }

    .divider-with-text-container-right::after {
        width: 5%;
    }
}