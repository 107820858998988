.page-root-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden; /* Change this to hidden */
    z-index: -1;
    .bg-ellipse-full-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: visible;
        z-index: -1;
        max-width: 100.2rem;
    }

    .bg-ellipse-full-wrapper .bg-ellipse {
        position: absolute;
        width: 40rem;
        height: 40rem;
        border-radius: 51%;
        z-index: -1;
        transition: 0.3s linear 0s;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        perspective: 1000px;
    }

    .bg-ellipse-full-wrapper .bg-ellipse-1 {
        right: -1.9rem;
        top: 50rem;
        background: linear-gradient(44.11deg, rgba(9, 97, 72, 0.75) 8.94%, rgba(255, 255, 255, 0) 86.73%);
    }

    .bg-ellipse-full-wrapper .bg-ellipse-2 {
        left: -5.9rem;
        top: 80rem;
        background: linear-gradient(44.11deg, rgba(124, 177, 235, 0.6) 8.94%, rgba(255, 255, 255, 0) 86.73%);
    }

    .bg-ellipse-full-wrapper .bg-ellipse-3 {
        left: 9rem;
        top: 30rem;
        background: linear-gradient(44.11deg, rgba(245, 198, 39, 0.6) 8.94%, rgba(255, 255, 255, 0) 86.73%);
    }

}