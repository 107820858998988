.component-cardvertical-container {
    box-sizing: border-box;
    padding: 0;
    margin:0;
    border: none;
    color: rgba(0,0,0,0.88);
    font-size: 14px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.2s,border-color 0.2s;
    aspect-ratio: 9/16;
    cursor: pointer;

    &:hover {
        // border-color: transparent;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),0 3px 6px 0 rgba(0, 0, 0, 0.12),0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }

    .image {
        padding: 0;
        margin: 0;
        width: 100%;
        object-fit: cover;
        height: 70%;
        overflow: hidden;
    }

    .content-container {
        height: 30%;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 600;
            font-size: 16px;
            white-space:nowrap;
            text-overflow: ellipsis;
            padding: 0;
            margin: 0;
        }

        .content {
            color: rgba(0,0,0,0.45);
            font-weight: 400;
        }

        .learn-more {
            width: fit-content;
            align-self: flex-end;
            font-size: 14px;
            color: rgba(0,0,0,0.45);
            &:hover{
                color: #1677ff;
                transition: color 0.2s;
            }
            a {
                margin-right: 0.2rem;

            }
        }
    }
}